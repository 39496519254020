import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ValidatorFn } from '@angular/forms';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';

export enum AvailableTypesTrackColors {
  Single = 'single',
  Speed = 'speed',
  Trip = 'trip',
  Sensor = 'sensor',
}

export const availableTracksColorTypes: { value: AvailableTypesTrackColors; name: string }[] = [
  { value: AvailableTypesTrackColors.Single, name: 'PLAIN' },
  { value: AvailableTypesTrackColors.Speed, name: 'BY_SPEED' },
  { value: AvailableTypesTrackColors.Trip, name: 'BY_TRIPS' },
  { value: AvailableTypesTrackColors.Sensor, name: 'BY_SENSOR' },
];

@Injectable({
  providedIn: 'root',
})
export class CreateSensorsService {
  arraySensors = new BehaviorSubject<any>([]);

  @tuiPure
  stringify(
    items: { id: string | number; title: string }[],
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map = new Map(
      items.map(
        ({ id, title }) => [id, this.translate.instant(title)] as [string | number, string],
      ),
    );

    return ({ $implicit }: TuiContextWithImplicit<string>) => map.get($implicit) || '';
  }

  availableMethodCalculateOdometer = [
    { id: 'gps', title: 'GPS' },
    { id: 'gps_ignition', title: 'GPS_IGNITION_SENSOR' },
  ];

  availableMethodCalculateAbsoluteEngineHours = [
    { id: 'ignition_sensor', title: 'Ignition sensor' },
  ];

  availableIntervalCalculateAbsoluteEngineHours = [
    { id: 3600, title: `1 ${this.translate.instant('HOUR')}` },
    { id: 10800, title: `3 ${this.translate.instant('HOUR_1')}` },
    { id: 21600, title: `6 ${this.translate.instant('HOURS')}` },
    { id: 43200, title: `12 ${this.translate.instant('HOURS')}` },
  ];

  availableIntervalCalculateOdometer = [
    { id: 300, title: `5 ${this.translate.instant('MINUTES')}` },
    { id: 900, title: `15 ${this.translate.instant('MINUTES')}` },
    { id: 1800, title: `30 ${this.translate.instant('MINUTES')}` },
    { id: 3600, title: `1 ${this.translate.instant('HOUR')}` },
  ];

  availableParamsCalculateSensors: any = {
    methodCalculate: [],
    intervalCalculate: [],
  };

  readonly defaultParameterTypes = [
    'time',
    'regtime',
    'sats',
    'speed',
    'course',
    'altitude',
    'lon',
    'lat',
  ];

  availableParameterTypes: string[] = [];

  // датчики: пробега,
  availableUnitSpeeds = ['км', 'ми'];

  // датчики: зажигания, мгновенного определения движения, произвольный цифровой,
  availableUnitOnOffs = ['выкл/вкл'];

  // датчики: напряжения,
  availableUnitVoltage = ['В'];

  // датчики: веса,
  availableUnitWeight = ['т', 'фунтов'];

  // датчики: акселерометр,
  availableUnitAccelerometer = ['g'];

  // датчики: температура,
  availableUnitTemperature = ['°C', '°F'];

  // датчики: обороты двигателя,
  availableUnitEngineSpeed = ['об/мин'];

  // датчики: полезной работы двигателя,
  availableUnitEngineEfficiency = [];

  // датчики: Абсолютные моточасы, Относительные моточасы
  availableUnitEngineHours = ['ч'];

  // датчики: все датчики топлива
  availableUnitFuel = ['л', 'гал'];

  constructor(private translate: TranslateService) {
    translate
      .get(['KM', 'MILES', 'on/off', 'V', 'T', 'POUNDS', 'RPM', 'HH', 'GAL', 'L'])
      .subscribe((res: any) => {
        this.availableUnitSpeeds = [res.KM, res.MILES];
        this.availableUnitOnOffs = [res['on/off']];
        this.availableUnitVoltage = [res.V];
        this.availableUnitWeight = [res.T, res.POUNDS];
        this.availableUnitEngineSpeed = [res.RPM];
        this.availableUnitEngineHours = [res.HH];
        this.availableUnitFuel = [res.L, res.GAL];
      });
  }

  setAvailableParamType(customParam: any) {
    this.availableParameterTypes = [...this.defaultParameterTypes, ...customParam];
  }

  getAvailableParamCalculationSensorsByType(type: 'odometer' | 'absolute_running_hours') {
    if (type === 'odometer') {
      return {
        methodCalculate: this.availableMethodCalculateOdometer,
        intervalCalculate: this.availableIntervalCalculateOdometer,
      };
    } else if (type === 'absolute_running_hours') {
      return {
        methodCalculate: this.availableMethodCalculateAbsoluteEngineHours,
        intervalCalculate: this.availableIntervalCalculateAbsoluteEngineHours,
      };
    } else {
      return null;
    }
  }

  getAvailableUnit(sensorType: string) {
    switch (sensorType) {
      case 'odometer':
        return this.availableUnitSpeeds;
      case 'ignition_sensor':
      case 'instant_motion_detection_sensor':
      case 'custom_digital_sensor':
        return this.availableUnitOnOffs;
      case 'voltage_sensor':
        return this.availableUnitVoltage;
      case 'weight_sensor':
        return this.availableUnitWeight;
      case 'accelerometer':
        return this.availableUnitAccelerometer;
      case 'temperature':
        return this.availableUnitTemperature;
      case 'engine_speed_sensor':
        return this.availableUnitEngineSpeed;
      case 'engine_usable_operation_sensor':
        return this.availableUnitEngineEfficiency;
      case 'absolute_running_hours':
      case 'relative_running_hours':
        return this.availableUnitEngineHours;
      case 'fuel_impulse_sensor':
      case 'absolute_fuel_impulse_sensor':
      case 'current_fuel_impulse_sensor':
      case 'fuel_level_sensor':
        return this.availableUnitFuel;
      default:
        return [];
    }
  }

  validatorUniqItemX(): ValidatorFn {
    return (itemsArray: any): { [key: string]: any } | null => {
      const items = itemsArray.controls;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const x = item.get('x').value;

        if (
          items.some((otherItem: any, index: any) => otherItem.get('x').value === x && index !== i)
        ) {
          item.setErrors({ uniqueItems: true });
        } else {
          item.setErrors(null);
        }
      }

      return null;
    };
  }
}
